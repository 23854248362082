







































































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { User } from '@/models/User.model'

@Component
export default class UserProfileForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  private formData: User | null = null
  private formDataThemeLanguage: User | null = null
  private isLoading = false
  private languages = [
    {
      value: 'de',
      text: 'Deutsch'
    },
    {
      value: 'en',
      text: 'English'
    }
  ]

  async save() {
    try {
      if (!this.formData) return
      this.isLoading = true
      await this.$store.dispatch('auth/updateUser', this.formData)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  async saveThemeLanguage() {
    try {
      if (!this.formDataThemeLanguage) return
      this.isLoading = true
      this.$vuetify.theme.dark = this.formDataThemeLanguage.darkMode()
      this.$i18n.locale = this.formDataThemeLanguage.language()
      await this.$store.dispatch('auth/updateUser', this.formDataThemeLanguage)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  created() {
    if (!this.currentUser) {
      throw new Error('No current User')
    }
    this.formData = User.createFromUser(this.currentUser)
    this.formDataThemeLanguage = User.createFromUser(this.currentUser)
  }

  get authenticated() {
    return this.$store.state.auth.isAuthenticated
  }

  get currentUser(): User {
    return this.$store.state.auth.currentUser
  }
}
