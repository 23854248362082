var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.authenticated)?_c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"3"}},[_c('v-avatar',{attrs:{"size":"200px","item":""}},[_c('v-img',{attrs:{"src":_vm.formData.avatarImageUrlBig(),"alt":_vm.$t('user.profile.image')}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.firstname'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('user.firstname'),"error-messages":errors[0],"autofocus":"","filled":""},model:{value:(_vm.formData.firstname),callback:function ($$v) {_vm.$set(_vm.formData, "firstname", $$v)},expression:"formData.firstname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.surname'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('user.surname'),"error-messages":errors[0],"filled":""},model:{value:(_vm.formData.surname),callback:function ($$v) {_vm.$set(_vm.formData, "surname", $$v)},expression:"formData.surname"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('user.settings.darkMode')},on:{"change":function($event){return _vm.saveThemeLanguage()}},model:{value:(_vm.formDataThemeLanguage.settings.darkMode),callback:function ($$v) {_vm.$set(_vm.formDataThemeLanguage.settings, "darkMode", $$v)},expression:"formDataThemeLanguage.settings.darkMode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-select',{attrs:{"label":_vm.$t('user.settings.language'),"items":_vm.languages,"filled":""},on:{"change":function($event){return _vm.saveThemeLanguage()}},model:{value:(_vm.formDataThemeLanguage.settings.language),callback:function ($$v) {_vm.$set(_vm.formDataThemeLanguage.settings, "language", $$v)},expression:"formDataThemeLanguage.settings.language"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"fab-container fab-container-bottom fab-container-right"},[_c('v-btn',{attrs:{"fab":"","color":"primary","type":"submit","loading":_vm.isLoading,"disabled":invalid}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1)]}}],null,false,654809469)})],1):_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }