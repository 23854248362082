














import { Component, Vue } from 'vue-property-decorator'
import UserProfileForm from '@/main/user/components/UserProfileForm.vue'
import { User } from '@/models/User.model'
@Component({
  components: { UserProfileForm }
})
export default class UserProfile extends Vue {
  get currentUser(): User {
    return this.$store.state.auth.currentUser
  }
}
